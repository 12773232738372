// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-episodes-tsx": () => import("./../../../src/pages/episodes.tsx" /* webpackChunkName: "component---src-pages-episodes-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pitches-tsx": () => import("./../../../src/pages/pitches.tsx" /* webpackChunkName: "component---src-pages-pitches-tsx" */),
  "component---src-pages-seasons-tsx": () => import("./../../../src/pages/seasons.tsx" /* webpackChunkName: "component---src-pages-seasons-tsx" */),
  "component---src-templates-episode-tsx": () => import("./../../../src/templates/Episode.tsx" /* webpackChunkName: "component---src-templates-episode-tsx" */),
  "component---src-templates-pitch-tsx": () => import("./../../../src/templates/Pitch.tsx" /* webpackChunkName: "component---src-templates-pitch-tsx" */),
  "component---src-templates-season-tsx": () => import("./../../../src/templates/Season.tsx" /* webpackChunkName: "component---src-templates-season-tsx" */)
}

